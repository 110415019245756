@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-LightItalic.woff2") format("woff2"),
    url("../fonts/default/Roboto-LightItalic.woff") format("woff"),
    url("../fonts/default/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/default/Roboto-Regular.woff") format("woff"),
    url("../fonts/default/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/default/Roboto-Medium.woff") format("woff"),
    url("../fonts/default/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-Light.woff2") format("woff2"),
    url("../fonts/default/Roboto-Light.woff") format("woff"),
    url("../fonts/default/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-BoldItalic.woff2") format("woff2"),
    url("../fonts/default/Roboto-BoldItalic.woff") format("woff"),
    url("../fonts/default/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-BlackItalic.woff2") format("woff2"),
    url("../fonts/default/Roboto-BlackItalic.woff") format("woff"),
    url("../fonts/default/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/default/Roboto-Bold.woff") format("woff"),
    url("../fonts/default/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-MediumItalic.woff2") format("woff2"),
    url("../fonts/default/Roboto-MediumItalic.woff") format("woff"),
    url("../fonts/default/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-Black.woff2") format("woff2"),
    url("../fonts/default/Roboto-Black.woff") format("woff"),
    url("../fonts/default/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-Italic.woff2") format("woff2"),
    url("../fonts/default/Roboto-Italic.woff") format("woff"),
    url("../fonts/default/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-ThinItalic.woff2") format("woff2"),
    url("../fonts/default/Roboto-ThinItalic.woff") format("woff"),
    url("../fonts/default/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/default/Roboto-Thin.woff2") format("woff2"),
    url("../fonts/default/Roboto-Thin.woff") format("woff"),
    url("../fonts/default/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}
