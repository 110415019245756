@font-face {
  font-family: "Audi Type";
  src: url("../fonts/audi/AudiType-Italic.woff2") format("woff2"),
    url("../fonts/audi/AudiType-Italic.woff") format("woff"),
    url("../fonts/audi/AudiType-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Audi Type";
  src: url("../fonts/audi/AudiType-BoldItalic.woff2") format("woff2"),
    url("../fonts/audi/AudiType-BoldItalic.woff") format("woff"),
    url("../fonts/audi/AudiType-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Audi Type";
  src: url("../fonts/audi/AudiType-Bold.woff2") format("woff2"),
    url("../fonts/audi/AudiType-Bold.woff") format("woff"),
    url("../fonts/audi/AudiType-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Audi Type Extended";
  src: url("../fonts/audi/AudiType-ExtendedBoldItalic.woff2") format("woff2"),
    url("../fonts/audi/AudiType-ExtendedBoldItalic.woff") format("woff"),
    url("../fonts/audi/AudiType-ExtendedBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Audi Type Extended";
  src: url("../fonts/audi/AudiType-ExtendedItalic.woff2") format("woff2"),
    url("../fonts/audi/AudiType-ExtendedItalic.woff") format("woff"),
    url("../fonts/audi/AudiType-ExtendedItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Audi Type Extended";
  src: url("../fonts/audi/AudiType-ExtendedBold.woff2") format("woff2"),
    url("../fonts/audi/AudiType-ExtendedBold.woff") format("woff"),
    url("../fonts/audi/AudiType-ExtendedBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Audi Type Extended";
  src: url("../fonts/audi/AudiType-ExtendedNormal.woff2") format("woff2"),
    url("../fonts/audi/AudiType-ExtendedNormal.woff") format("woff"),
    url("../fonts/audi/AudiType-ExtendedNormal.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Audi Type Wide";
  src: url("../fonts/audi/AudiType-WideLight.woff2") format("woff2"),
    url("../fonts/audi/AudiType-WideLight.woff") format("woff"),
    url("../fonts/audi/AudiType-WideLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Audi Type Wide";
  src: url("../fonts/audi/AudiType-WideBold.woff2") format("woff2"),
    url("../fonts/audi/AudiType-WideBold.woff") format("woff"),
    url("../fonts/audi/AudiType-WideBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Audi Type";
  src: url("../fonts/audi/AudiType-Normal.woff2") format("woff2"),
    url("../fonts/audi/AudiType-Normal.woff") format("woff"),
    url("../fonts/audi/AudiType-Normal.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Audi Type Wide";
  src: url("../fonts/audi/AudiType-WideNormal.woff2") format("woff2"),
    url("../fonts/audi/AudiType-WideNormal.woff") format("woff"),
    url("../fonts/audi/AudiType-WideNormal.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
