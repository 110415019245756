html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  min-height: 400px;
}

a {
  color: hsl(0, 0%, 56%);
  text-decoration: none;
}
