/* This stylesheet generated by Transfonter (https://transfonter.org) on November 4, 2017 2:08 AM */

@font-face {
  font-family: "SKODA Next";
  src: url("../fonts/skoda/SKODANext-BlackItalic.eot");
  src: local("SKODA Next Black Italic"), local("SKODANext-BlackItalic"),
    url("../fonts/skoda/SKODANext-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/skoda/SKODANext-BlackItalic.woff") format("woff"),
    url("../fonts/skoda/SKODANext-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "SKODA Next-black";
  src: url("../fonts/skoda/SKODANext-Black.eot");
  src: local("SKODA Next Black"), local("SKODANext-Black"),
    url("../fonts/skoda/SKODANext-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/skoda/SKODANext-Black.woff") format("woff"),
    url("../fonts/skoda/SKODANext-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "SKODA Next";
  src: url("../fonts/skoda/SKODANext-BoldItalic.eot");
  src: local("SKODA Next Bold Italic"), local("SKODANext-BoldItalic"),
    url("../fonts/skoda/SKODANext-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/skoda/SKODANext-BoldItalic.woff") format("woff"),
    url("../fonts/skoda/SKODANext-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "SKODA Next Black Expanded";
  src: url("../fonts/skoda/SKODANext-BlackExpanded.eot");
  src: local("SKODA Next Black Expanded"), local("SKODANext-BlackExpanded"),
    url("../fonts/skoda/SKODANext-BlackExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/skoda/SKODANext-BlackExpanded.woff") format("woff"),
    url("../fonts/skoda/SKODANext-BlackExpanded.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "SKODA Next";
  src: url("../fonts/skoda/SKODANext-Italic.eot");
  src: local("SKODA Next Italic"), local("SKODANext-Italic"),
    url("../fonts/skoda/SKODANext-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/skoda/SKODANext-Italic.woff") format("woff"),
    url("../fonts/skoda/SKODANext-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "SKODA Next";
  src: url("../fonts/skoda/SKODANext-Light.eot");
  src: local("SKODA Next Light"), local("SKODANext-Light"),
    url("../fonts/skoda/SKODANext-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/skoda/SKODANext-Light.woff") format("woff"),
    url("../fonts/skoda/SKODANext-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "SKODA Next";
  src: url("../fonts/skoda/SKODANext-Bold.eot");
  src: local("SKODA Next Bold"), local("SKODANext-Bold"),
    url("../fonts/skoda/SKODANext-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/skoda/SKODANext-Bold.woff") format("woff"),
    url("../fonts/skoda/SKODANext-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "SKODA Next";
  src: url("../fonts/skoda/SKODANext-LightItalic.eot");
  src: local("SKODA Next Light Italic"), local("SKODANext-LightItalic"),
    url("../fonts/skoda/SKODANext-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/skoda/SKODANext-LightItalic.woff") format("woff"),
    url("../fonts/skoda/SKODANext-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "SKODA Next";
  src: url("../fonts/skoda/SKODANext-Regular.eot");
  src: local("SKODA Next"), local("SKODANext-Regular"),
    url("../fonts/skoda/SKODANext-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/skoda/SKODANext-Regular.woff") format("woff"),
    url("../fonts/skoda/SKODANext-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
